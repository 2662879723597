import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts/main/Layout'
import Container from '../components/Container'
import SEO from '../components/util/seo/Seo'

interface HomepageData {
  fields: {
    slug: string
  }
  frontmatter: {
    pageKey: string
    seo_title: string
    seo_desc: string
    title: string
    text: string
  }
}

interface IndexProps {
  pageContext: {
    [locale: string]: string
  }
  location: any
  data: {
    homePageData: HomepageData
  }
}

const IndexPage = ({ pageContext: { locale }, location, ...props }: IndexProps): ReactElement => {
  const { homePageData: data } = props.data
  return (
    <Layout locale={locale}>
      <SEO lang={locale} title="Marcin Masecki" metaDescriptionPl="Pianista, kompozytor, aranżer, dyrygent, kurator. Jego działalność obejmuje szerokie spektrum stylistyczne, a wirtuozowskie łączenie gatunków oraz łamanie granic między sztuką wysoką a rozrywkową stały się jego znakami rozpoznawczymi." metaDescriptionEn="Masecki is one of Poland’s most prolific and idiosyncratic musicians. Winner of many awards he performs extensively on all keyboard instruments, composes for film and theatre, produces festivals, records and curates musical events." />
      <Container locale={locale} data={data.frontmatter} location={location} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeContent($locale: String) {
    homePageData: markdownRemark(frontmatter: { pageKey: { eq: "page_home" }, locale: { eq: $locale } }) {
      fields {
        slug
      }
      frontmatter {
        pageKey
        seo_title
        seo_desc
        title
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bio_title
        bio_text
        calendar_title
        calendar {
          month
          event {
            text
            url
          }
        }
        contact {
          heading
          item {
            name
            mail
          }
        }
        credits {
          name
          mail
        }
        film {
          title
          list {
            title
            year
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            yt_id
            description
            links {
              type
              url
              label
            }
          }
          extra_title
          extra_text
        }
        classical {
          title
          list {
            title
            year
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            yt_id
            description
            links {
              type
              url
              label
            }
          }
          extra_title
          extra_text
        }
        nonclassical {
          title
          list {
            title
            year
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            yt_id
            description
            links {
              type
              url
              label
            }
          }
          extra_title
          extra_text
        }
      }
    }
  }
`

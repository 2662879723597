import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import remark from 'remark'
import remarkHTML from 'remark-html'
import Heading from './Heading'
import Paragraph from './Paragraph'
import Month from './Month'
import Contact from './Contact'
import Credits from './Credits'

const Wrapper = styled.div`
  background-color: ${({ isHome }) => (isHome ? 'var(--col-bg-home)' : 'var(--col-bg)')};
`

const PageTitle = styled(Heading)`
  font-size: 40px;
  margin-bottom: 5px;

  strong {
    text-transform: initial;
  }

  @media only screen and (min-width: 1000px) {
    border-bottom: 0;
    position: relative;
    color: ${({ blend }) => (blend ? 'white' : 'var(--col-body)')};
    mix-blend-mode: ${({ blend }) => (blend ? 'difference' : 'undefined')};
    margin-bottom: 0;
    padding-bottom: 0;
    transition: color 0.35s ease-in-out;

    &:after {
      display: none;
    }
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;

  @media only screen and (min-width: 1000px) {
    position: absolute;
    z-index: 0;
    top: 5px;
    right: 0;
    padding: 0;
    transition: filter 0.2s ease-in-out;
    display: inline-block;
    margin-bottom: 0;
  }
`
const Image = styled.img`
  width: 100%;

  @media only screen and (min-width: 1000px) {
    width: 50vh;
    height: auto;
  }
`

const Text = styled(Paragraph)`
  margin-bottom: 80px;

  @media only screen and (min-width: 1000px) {
    max-width: 90%;
    margin-bottom: 100px;
    margin-top: 60px;
  }
`

const CalWrapper = styled.div`
  margin-bottom: 60px;

  @media only screen and (min-width: 1000px) {
    display: ${({ block }) => (block ? 'block' : 'inline-block')};
    width: 50%;
    padding-right: 17px;

    &:not(:last-of-type) {
      margin-bottom: 0;
      padding-bottom: 1px;
    }
  }
`

const About = ({ data, isHome }) => {

  const [isDesktop, setIsDesktop] = useState(false)
  const viewportWidth = typeof window !== 'undefined' ? window.innerWidth || document.documentElement.clientWidth : 1500
  // const isDesktop = viewportWidth >= 1000


  
  
  useEffect(() => {
    const mq = typeof window !== 'undefined' ? window.matchMedia('(min-width: 1000px)') : false
    setIsDesktop (mq.matches)
  }, [])

  return (
    <Wrapper isHome={isHome}>
      <PageTitle
        blend={isHome}
        as="h1"
        dangerouslySetInnerHTML={{
          __html: remark()
            .use(remarkHTML)
            .processSync(data.title)
            .toString(),
        }}
      />

      <ImageWrapper>
        <Image src={data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image} />
      </ImageWrapper>

      <CalWrapper>
        {data.calendar.map((month, i) => {
          if (i === 0 || !isDesktop) {
            return <Month data={month} key={i} index={i} />
          } else {
            return false
          }
        })}
      </CalWrapper>

      <Contact data={data.contact} />

      {isDesktop ? (
        <CalWrapper block={true}>
          {data.calendar.map((month, i) => {
            if (i !== 0) {
              return <Month data={month} key={i} index={i} />
            } else {
              return
            }
          })}
        </CalWrapper>
      ) : (
        false
      )}

      <Text
        small={true}
        dangerouslySetInnerHTML={{
          __html: remark()
            .use(remarkHTML)
            .processSync(data.bio_text)
            .toString(),
        }}
      />

      <Credits data={data.credits} />
    </Wrapper>
  )
}

export default About

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Event from './Event'

const Title = styled.h4`
  padding: 15px 0 13px;
  border-top: ${({ noBorder }) => (noBorder ? '0' : '1px solid var(--col-body)')};
  border-bottom: 1px solid var(--col-body);
  font-family: atak, sans-serif;
  font-size: 18px;
  transition: border 0.25s ease-in-out;

  @media only screen and (min-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Month = ({ data, index }) => {
  return (
    <div>
      <Title noBorder={true}>{data.month}</Title>
      {data.event.map((event, j) => {
        return <Event key={j} data={event} />
      })}
    </div>
  )
}

export default Month

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import remark from 'remark'
import remarkHTML from 'remark-html'

const Wrapper = styled.div`
  margin-bottom: 50px;

  @media only screen and (min-width: 1000px) {
      margin-bottom: 0;
  }
`

const Line = styled.div``

const Name = styled.span`
  font-family: atak, sans-serif;
  font-size: 16px;
  line-height: 20px;
`

const Links = styled.span`
  font-size: 16px;
  line-height: 20px;

  p {
    display: inline;
  }

  a {
    text-decoration: underline;
  }
`

const Credits = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, i) => {
        return (
          <Line key={i}>
            <Name>{item.name} </Name>
            <Links
              dangerouslySetInnerHTML={{
                __html: remark()
                  .use(remarkHTML)
                  .processSync(item.mail)
                  .toString()
                  .replace('href=', 'target="_blank" href='),
              }}
            />
          </Line>
        )
      })}
    </Wrapper>
  )
}

export default Credits

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import remark from 'remark'
import remarkHTML from 'remark-html'
import Heading from './Heading'
import Project from './Project'
import Paragraph from './Paragraph'

const Subheading = styled(Heading)`
  padding-bottom: 20px;
  border-bottom: 1px solid var(--col-body);
  margin-bottom: 20px;
  transition: border 0.25s ease-in-out;

  @media only screen and (min-width: 1000px) {
    border-bottom: 0;
    padding-bottom: 14px;
  }
`

const Projects = ({ data: content, loc, players, stopVideos, isActive, ytReady }) => {
  return (
    <div>
      <Heading
        dangerouslySetInnerHTML={{
          __html: remark()
            .use(remarkHTML)
            .processSync(content.title)
            .toString(),
        }}
      />
      {content.list &&
        content.list.map((item, i) => {
          return <Project ytReady isActive={isActive} stopVideos={stopVideos} player={players[i]} data={item} loc={loc} key={i} last={content.list.length === i + 1} />
        })}
      {content.extra_title && (
        <Subheading
          dangerouslySetInnerHTML={{
            __html: remark()
              .use(remarkHTML)
              .processSync(content.extra_title)
              .toString(),
          }}
        />
      )}
      {content.extra_text && (
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: remark()
              .use(remarkHTML)
              .processSync(content.extra_text)
              .toString(),
          }}
        />
      )}
    </div>
  )
}

export default Projects

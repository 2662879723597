import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 13px 0 10px;;
  display: block;
  border-bottom: 1px solid var(--col-body);
  line-height: 20px;
  font-size: 18px;
  transition: border 0.25s ease-in-out;

  &:last-child {
    border-bottom: 0;
  }

  @media only screen and (min-width: 1000px) {
    line-height: 21px;
    font-size: 16px;
  }
`

const Event = ({ data }) => {
  return (
    <Wrapper as={data.url ? 'a' : undefined} href={data.url ? data.url : undefined} target="_blank">
      {data.text}
    </Wrapper>
  )
}

export default Event

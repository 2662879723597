import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'
import { i18n } from '../constants/i18n'
import Description from './Paragraph'

const Wrapper = styled.div`
  border-bottom: ${({ last }) => (last ? '0' : '1px solid var(--col-body)')};
  margin-bottom: ${({ last }) => (last ? '90px' : '15px')};
  transition: border 0.25s ease-in-out;

  @media only screen and (min-width: 1000px) {
    margin-bottom: ${({ last }) => (last ? '120px' : '15px')};
  }
`

const Title = styled.h3`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  font-family: atak, serif;
`

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: height 0.15s ease-in-out, width 0.15s ease-in-out, opacity 0.25s ease-in-out;
  pointer-events: none;
  opacity: ${({ hideImage }) => (hideImage ? 0 : 1)};

  &:after {
    content: 'Play';
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    font-family: atak, sans-serif;
    transition: opacity 0.2s ease-in-out;
  }
`

const EmbedWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50vw;
  margin-bottom: 20px;

  max-width: 600px;
  max-height: 350px;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1000px) {
    margin-bottom: 30px;

    &:hover {
      ${Dot} {
        text-align: center;
        line-height: 60px;
        height: 60px;
        width: 60px;
        font-family: atak, sans-serif;

        &:after {
          opacity: 1;
        }
      }
    }
  }
`

const Background = styled.img`
  height: calc(100% + 3px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -2px;
  right: 0;
  background-color: var(--col-body);
  opacity: ${({ hideImage }) => (hideImage ? 0 : 1)};
  pointer-events: ${({ hideImage }) => (hideImage ? 'none' : 'initial')};
  transition: opacity 0.25s ease-in-out, background-color 0.45s ease-in-out;
`

const Image = styled.img`
  height: calc(100% + 3px);
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: -2px;
  right: 0;
  left: 0;
  mix-blend-mode: screen;
  cursor: ${({cursorAuto}) => cursorAuto ? 'auto' : 'pointer'};
  opacity: ${({ hideImage }) => (hideImage ? 0 : 1)};
  pointer-events: ${({ hideImage }) => (hideImage ? 'none' : 'initial')};
  transition: opacity 0.25s ease-in-out;
`

const Links = styled.div`
  margin-bottom: 65px;

  @media only screen and (min-width: 1000px) {
    margin-bottom: 90px;
  }
`

const Link = styled.a`
  margin-right: 20px;
  text-decoration: underline;
`

const Project = ({ data, loc, last, player, stopVideos, isActive, ytReady }) => {
  const [hideImage, setHideImage] = useState(false)

  const handlePlay = () => {
    setHideImage(true)
    if (player && player.playVideo) {
      stopVideos()
      player.playVideo()
    }
  }


  return (
    <Wrapper last={last}>
      <Title>
        {data.title} {data.year && `(${data.year})`}
      </Title>
      <EmbedWrapper hideImage={hideImage}>
        {data.yt_id && (
          <iframe
            width="560"
            height="315"
            src={`//www.youtube.com/embed/${data.yt_id}?enablejsapi=1&?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            id={data.yt_id}
          ></iframe>
        )}
        <Background hideImage={hideImage} />
        <Image
          hideImage={hideImage}
          cursorAuto={data.yt_id === null}
          onClick={(isActive && ytReady &&  data.yt_id) ? handlePlay : undefined}
          src={data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image}
        />
        {data.yt_id && <Dot hideImage={hideImage} />}
      </EmbedWrapper>
      <Description>{data.description}</Description>
      {data.links && data.links.length > 0 && (
        <Links>
          {data.links.map((item, i) => {
            return (
              <Link key={i} href={item.url} target="_blank">
                {item.type === 'youtube' && 'YouTube'}
                {item.type === 'spotify' && 'Spotify'}
                {item.type === 'other' && (item.label ? item.label : i18n[loc].link_label)}
              </Link>
            )
          })}
        </Links>
      )}
    </Wrapper>
  )
}

export default Project

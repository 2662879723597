import styled from 'styled-components'

export default styled.div`
  font-size: ${({small}) => small ? '18px' : '26px'};
  line-height: ${({small}) => small ? '28px' : '34px'};
  margin-bottom: 20px;
  max-width: 1000px;

  @media only screen and (min-width: 1000px) {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -0.5px;
  }

  p:not(:last-of-type) {
    margin-bottom: 30px;

    @media only screen and (min-width: 1000px) {
      margin-bottom: 55px;
    }
  }
`
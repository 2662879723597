export const i18n = {
  en: {
    about_drawer: 'INFORMATION',
    link_label: 'Link',
  },
  pl: {
    about_drawer: 'INFORMACJE',
    link_label: 'Link',
  },
}

import React from 'react'
import styled from 'styled-components'
import remark from 'remark'
import remarkHTML from 'remark-html'

const Wrapper = styled.div`
  height: ${({ isActive }) => (isActive ? 'calc(100vh - 35px * 4)' : '35px')};
  width: 100%;
  overflow: hidden;
  transition: ${({ isTransition }) => (isTransition ? 'width 0.3s ease-in-out, height 0.3s ease-in-out' : 'width 0.15s ease-in-out, height 0.15s ease-in-out')};
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  position: relative;
  padding-top: 35px;

  @media only screen and (min-width: 1000px) {
    width: ${({ isActive }) => (isActive ? 'calc(100vw - 35px * 4)' : '35px')};
    height: 100%;
    padding-top: 0;

    &:hover {
      width: ${({ isActive }) => (isActive ? 'calc(100vw - 35px * 4)' : '65px')};
    }

    a {
      pointer-events: ${({isActive}) => isActive ? 'initial' : 'none'}
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 0;
    height: 1px;
    background-color: var(--col-body);
    transition: background-color 0.25s ease-in-out;

    @media only screen and (min-width: 1000px) {
      left: auto;
      right: 0;
      bottom: 25px;
      top: 25px;
      height: auto;
      width: 1px;
    }
  }
`

const RotatedText = styled.span`
  display: block;
  transform: translateX(-50%);
  position: absolute;
  top: 4px;
  left: 50%;
  font-family: atak, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  width: 100%;
  text-align: center;

  strong {
    font-family: inherit;
  }

  @media only screen and (min-width: 1000px) {
    transform: rotate(-90deg) translate(calc(50vh - 50%), -100vh);
    left: 16px;
    transform-origin: right;
    width: 100vh;
    text-align: center;
    z-index: 1;
  }
`

const ScrollWrapper = styled.div`
  padding: 10px;
  position: absolute;
  width: 100%;
  overflow-y: ${({ isActive }) => (isActive ? 'scroll' : 'hidden')};;
  height: 100%;
  top: 0;
  transform: ${({ isActive }) => (isActive ? 'translateY(0)' : 'translateY(40px)')};
  transition: transform 0.35s ease-in-out;

  @media only screen and (min-width: 1000px) {
    padding: 25px 0;
    transform: translateY(0);
  }
`

const Content = styled.div`
  height: 100%;
  opacity: ${({showContent}) => showContent ? 1 : 0};
  transition: background-color 0.25s ease-in-out, color 0.15s ease-in-out, opacity 0.25s ease-in-out;

  @media only screen and (min-width: 1000px) {
    width: calc(100vw - 35px * 4 - 35px);
    height: auto;
    margin: 0 auto;
    position: absolute;
    padding-bottom: 25px;
    padding-left: 35px;
    margin-top: -10px;
    background-color: ${({ isHome }) => (isHome ? 'var(--col-bg-home)' : 'var(--col-bg)')};
  }
`

const Drawer = ({ title, isActive, onClick, children, isTransition, isHome, showContent }) => {
  return (
    <Wrapper isActive={isActive} onClick={isActive ? undefined : onClick} isTransition={isTransition}>
      <RotatedText
        isActive={isActive}
        dangerouslySetInnerHTML={{
          __html: remark()
            .use(remarkHTML)
            .processSync(title)
            .toString(),
        }}
      ></RotatedText>
      <ScrollWrapper isActive={isActive}>
        <Content showContent={showContent} isHome={isHome}>{children}</Content>
      </ScrollWrapper>
    </Wrapper>
  )
}

export default Drawer

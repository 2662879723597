import styled from 'styled-components'

export default styled.h2`
  font-size: 40px;
  letter-spacing: -0.72px;
  line-height: 34px;
  text-transform: uppercase;
  color: inherit;
  margin-bottom: ${({ smallMargin }) => (smallMargin ? '10px' : '35px')};
  font-family: atak, sans-serif;

  @media only screen and (min-width: 1000px) {
    font-size: 120px;
    letter-spacing: -4px;
    padding-bottom: 20px;
    line-height: 0.9;
    margin-bottom: ${({ smallMargin }) => (smallMargin ? '10px' : '15px')};
    position: relative;
    z-index: 1;
    margin-left: -5px;

    strong {
      letter-spacing: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 5px;
      right: 0;
      height: ${({ noBorder }) => (noBorder ? '0' : '1px')};
      background-color: var(--col-body);
      transition: background-color 0.5s ease-in-out 0.1s;
    }
  }
`

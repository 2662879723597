import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 60px;

  @media only screen and (min-width: 1000px) {
    width: 50%;
    display: inline-block;
    padding-left: 17px;
    margin-bottom: 0;
    margin-top: calc(100vh - 370px);
  }
`

const Heading = styled.h3`
  font-family: atak, sans-serif;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;

  @media only screen and (min-width: 1000px) {
    font-size: 16px;
  }
`

const ContactLine = styled.div`
  border-top: 1px solid var(--col-body);
  padding: 13px 0 10px;
  line-height: 1;
  transition: border 0.25s ease-in-out;
`

const Name = styled.span`
  font-family: atak, sans-serif;
  font-size: 18px;
  line-height: 24px;

  @media only screen and (min-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`
const MailLink = styled.a`
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;

  @media only screen and (min-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Contact = ({ data }) => {
  return (
    <Wrapper>
      <Heading>{data.heading}</Heading>
      {data.item.map((line, i) => {
        return (
          <ContactLine key={i}>
            <Name>{line.name} </Name>
            <MailLink href={line.mail} target="_blank">
              {line.mail}
            </MailLink>
          </ContactLine>
        )
      })}
    </Wrapper>
  )
}

export default Contact

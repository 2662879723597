import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { i18n } from '../constants/i18n'

import Drawer from '../components/Drawer'
import About from '../components/About'
import Projects from '../components/Projects'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ isHome }) => (isHome ? 'var(--col-bg-home)' : 'var(--col-bg)')};
  display: flex;
  flex-direction: column;
  transition: background-color 0.25s ease-in-out, color 0.2s ease-in-out;
  color: var(--col-body);
  font-size: 16px;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
`

const StyledLink = styled(Link)`
  font-size: 20px;
  height: 35px;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
  color: var(--col-body);
  line-height: 35px;

  @media only screen and (min-width: 1000px) {
    height: 100%;
    width: 35px;

    &:hover {
      color: #fff;
      background-color: var(--col-body);
    }
  }
`

const RotatedText = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: atak, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;

  @media only screen and (min-width: 1000px) {
    transform: rotate(-90deg) translate(calc(50vh - 50%), -100vh);
    left: 16px;
    transform-origin: right;
    width: 100vh;
    text-align: center;
  }
`

const Container = ({ locale, data, location, preview }) => {
  const [active, setActive] = useState(3)
  const [isTransition, setIsTransition] = useState(false)
  const [fontsLoaded, setFontsLoaded] = useState(false)
  const [ytReady, setYtReady] = useState(false)
  const filmPlayers = useRef([])
  const classicalPlayers = useRef([])
  const nonclassicalPlayers = useRef([])

  const loc = locale || 'en'

  // ----------------------

  const fontsLoadCheck = () => {
    if (typeof window !== 'undefined') {
      document.fonts.ready.then(function() {
        setFontsLoaded(true)
      })
    }
  }

  // --------------------------------

  const setTransitioning = () => {
    setIsTransition(true)
    setTimeout(() => {
      setIsTransition(false)
    }, 310)
  }

  const onLocationChange = location => {
    const bodyEl = preview ? document.getElementsByClassName('frame-content')[0] : document.body

    setTransitioning()

    switch (location.hash) {
      case '#film':
        setActive(0)
        bodyEl && bodyEl.style.setProperty('--col-body', '#326de7')
        bodyEl && bodyEl.style.setProperty('--col-body-selection', '#8FAFF3')
        break
      case '#nonclassical':
        setActive(1)
        bodyEl && bodyEl.style.setProperty('--col-body', '#fa5135')
        bodyEl && bodyEl.style.setProperty('--col-body-selection', '#FFA99B')
        break
      case '#classical':
        setActive(2)
        bodyEl && bodyEl.style.setProperty('--col-body', '#7e471c')
        bodyEl && bodyEl.style.setProperty('--col-body-selection', '#C99D7A')
        break
      default:
        setActive(3)
        bodyEl && bodyEl.style.setProperty('--col-body', '#111111')
        bodyEl && bodyEl.style.setProperty('--col-body-selection', '#999999')
    }
  }

  const onDrawerClick = (i, hash) => {
    if (location) window.location.hash = hash

    if (preview) {
      setActive(i)
    } else {
      onLocationChange(window.location)
    }

    stopVideos()
  }

  useEffect(() => {
    fontsLoadCheck()

    if (location) {
      setTimeout(() => {
        onLocationChange(window.location)
      }, 700)
    }

    var tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    const filmVideos = []
    const classicalVideos = []
    const nonclassicalVideos = []

    data.film.list.map(item => {
      filmVideos.push(item.yt_id ? item.yt_id : false)
    })
    data.classical.list.map(item => {
      classicalVideos.push(item.yt_id ? item.yt_id : false)
    })
    data.nonclassical.list.map(item => {
      nonclassicalVideos.push(item.yt_id ? item.yt_id : false)
    })

    window.onYouTubeIframeAPIReady = () => {
      for (var i = 0; i < filmVideos.length; i++) {
        if (filmVideos[i]) {
          var curplayer = createPlayer(filmVideos[i])
          filmPlayers.current[i] = curplayer
        } else {
          filmPlayers.current[i] = false
        }
      }
      for (var i = 0; i < nonclassicalVideos.length; i++) {
        if (nonclassicalVideos[i]) {
          var curplayer = createPlayer(nonclassicalVideos[i])
          nonclassicalPlayers.current[i] = curplayer
        } else {
          nonclassicalPlayers.current[i] = false
        }
      }
      for (var i = 0; i < classicalVideos.length; i++) {
        if (classicalVideos[i]) {
          var curplayer = createPlayer(classicalVideos[i])
          classicalPlayers.current[i] = curplayer
        } else {
          classicalPlayers.current[i] = false
        }
      }

      setYtReady(true)
    }

    const createPlayer = playerInfo => {
      return new YT.Player(playerInfo, {
        videoId: playerInfo,
      })
    }
  }, [])

  useEffect(() => {
    onLocationChange(window.location)
  }, [typeof window !== 'undefined' && window.location.hash])

  const stopVideos = () => {
    if (filmPlayers.current.length > 0) {
      filmPlayers.current.map(item => {
        if (item && item.pauseVideo) item.pauseVideo()
      })
    }
    if (classicalPlayers.current.length > 0) {
      classicalPlayers.current.map(item => {
        if (item && item.pauseVideo) item.pauseVideo()
      })
    }
    if (nonclassicalPlayers.current.length > 0) {
      nonclassicalPlayers.current.map(item => {
        if (item && item.pauseVideo) item.pauseVideo()
      })
    }
  }

  return (
    <Wrapper isHome={active === 3}>
      <Drawer
        title={data.film.title}
        isTransition={isTransition}
        isActive={active === 0}
        isHome={active === 3}
        onClick={() => onDrawerClick(0, 'film')}
        showContent={fontsLoaded}
      >
        <Projects
          isActive={active === 0}
          ytReady={ytReady}
          loc={loc}
          data={data.film}
          players={filmPlayers.current}
          stopVideos={stopVideos}
        />
      </Drawer>
      <Drawer
        title={data.nonclassical.title}
        isTransition={isTransition}
        isActive={active === 1}
        isHome={active === 3}
        onClick={() => onDrawerClick(1, 'nonclassical')}
        showContent={fontsLoaded}
      >
        <Projects
          isActive={active === 1}
          ytReady={ytReady}
          loc={loc}
          data={data.nonclassical}
          players={nonclassicalPlayers.current}
          stopVideos={stopVideos}
        />
      </Drawer>
      <Drawer
        title={data.classical.title}
        isTransition={isTransition}
        isActive={active === 2}
        isHome={active === 3}
        onClick={() => onDrawerClick(2, 'classical')}
        showContent={fontsLoaded}
      >
        <Projects
          isActive={active === 2}
          ytReady={ytReady}
          loc={loc}
          data={data.classical}
          players={classicalPlayers.current}
          stopVideos={stopVideos}
        />
      </Drawer>
      <Drawer
        title={i18n[loc].about_drawer}
        isTransition={isTransition}
        isActive={active === 3}
        isHome={active === 3}
        onClick={() => onDrawerClick(3, '')}
        showContent={fontsLoaded}
      >
        <About isHome={active === 3} loc={loc} data={data} />
      </Drawer>

      <StyledLink to={locale && locale === 'en' ? '/' : '/en'}>
        <RotatedText>{locale && locale === 'en' ? 'PL' : 'EN'}</RotatedText>
      </StyledLink>
    </Wrapper>
  )
}

export default Container
